import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import config from "../../config/website";

const SEO = ({ title, location }) => {
  const data = useStaticQuery(
    graphql`
      query SEO {
        site {
          buildTime(formatString: "YYYY-MM-DD")
        }
      }
    `
  );

  const _title = `${title} | ${config.siteTitle}`;
  const description = config.siteDescription;

  const realPrefix = config.pathPrefix === "/" ? "" : config.pathPrefix;
  const homeURL = `${config.siteUrl}${realPrefix}`;
  const image = `${homeURL}${config.siteLogo}`;

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: homeURL,
    headline: config.siteHeadline,
    inLanguage: "en",
    mainEntityOfPage: homeURL,
    description: config.siteDescription,
    name: _title,
    author: {
      "@type": "Person",
      name: config.author
    },
    copyrightHolder: {
      "@type": "Person",
      name: config.author
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: config.author
    },
    publisher: {
      "@type": "Person",
      name: config.author
    },
    datePublished: "2019-01-17",
    dateModified: data.site.buildTime,
    image: {
      "@type": "ImageObject",
      url: image
    }
  };

  return (
    <Helmet>
      <html lang={config.siteLanguage} />
      <title>{_title}</title>
      <link rel="canonical" href={`${homeURL}${location.pathname}`} />
      <link rel="apple-touch-icon" href="/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
      <link rel="shortcut icon" href="favicon.ico" />
      <meta name="msapplication-TileColor" content={config.backgroundColor} />
      <meta name="msapplication-config" content="browserconfig.xml" />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta property="og:locale" content={config.ogLanguage} />
      <meta property="og:site_name" content={config.ogSiteName} />
      <meta property="og:title" content={_title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object
};

SEO.defaultProps = {
  location: {
    pathname: ""
  }
};

export default SEO;
